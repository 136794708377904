import { Height } from "@mui/icons-material";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
	palette: {
		mode: "light",
		primary: {
			main: "#000000", // Black
		},
		secondary: {
			main: "#FFFFFF", // White
		},
		text: {
			primary: "#000000",
			secondary: "#000000",
		},
		background: {
			default: "#FFFFFF",
			paper: "#FFFFFF",
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: (theme) => ({
				body: {
					"&::-webkit-scrollbar": {
						width: "8px !important",
					},
					"&::-webkit-scrollbar-track": {
						backgroundColor: `${theme.palette.secondary.main} !important`,
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: `${theme.palette.primary.main} !important`,
						borderRadius: "10px !important",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor: theme.palette.primary.main,
					},
				},
				"& .MuiDrawer-paper": {
					"&::-webkit-scrollbar": {
						width: "8px",
					},
					"&::-webkit-scrollbar-track": {
						backgroundColor: `${theme.palette.secondary.main} !important`,
					},
				},
			}),
		},
	},
});

export default theme;
