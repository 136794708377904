import React from 'react';

const Maintenance = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            margin:'0px'
        }}>
            <h1>Site Under Maintenance</h1>
            <p>We are currently performing scheduled maintenance. Please check back later.</p>
        </div>
    );
};

export default Maintenance;